import { first_possible_day } from '../common/constants';
import { show_sticky_toast } from '../common/toast';
import { empty_element, hide_element } from '../common/ui';
import { Conf } from '../glaze/conf';
import { show_tests_table } from './tests';

export class SearchReport extends Conf {
  constructor(currents, styles) {
    super(currents, styles);
    this.search_doc_classes = currents['classes'] || [];
    globalThis.addEventListener('perform-model-role-search', () =>
      show_tests_table(
        { classes: this.target, title: document.getElementById('search_in_home').value },
        this.destination
      )
    );
    globalThis.addEventListener('compare-reports-search', () =>
      show_tests_table(
        { classes: globalThis.comparing_classes, title: document.getElementById('search_in_home').value },
        'compare'
      )
    );
    globalThis.addEventListener('report-search-results', () => show_tests_table({}));
  }

  view(parent) {
    empty_element(parent);
    this.skip = ['name', 'comment', 'alternatives', 'tags', 'results', 'limit', 'queryHash'];
    const options_descs = [];
    for (let handle of this.sections['main']) {
      if (this.options[handle].type === 'Section') {
        this.skip.push(handle);
      }
      if (!this.skip.includes(handle)) {
        const option_desc = this.options[handle].create_template_option(true);
        options_descs.push([option_desc, handle]);
      } else {
        this.options[handle].hidden = true;
      }
    }
    let doc_elems_list = { conf_uuid: JSON.stringify(this.uuid) };
    doc_elems_list['option_uuid_list'] = JSON.stringify({
      option_uuid_list: options_descs.map((x) => x[0]),
      conf_uuid: JSON.stringify(this.uuid),
    });
    const search_doc_template = require('../view/document/document_search.handlebars');
    const search_doc_html = search_doc_template(doc_elems_list);
    parent.insertAdjacentHTML('beforeend', search_doc_html);
  }

  is_search() {
    return true;
  }

  search_elements_created() {
    this.full_edit();
    for (const opt of Object.values(this.options)) {
      if (opt.attr.includes('ReadOnly') && (!opt.current || ['"-"', '""'].includes(opt.current))) {
        hide_element(opt.uid);
      }
    }
    return on_load_tests();
  }

  clear_search_doc() {
    reset_table();
  }

  do_search() {
    this.options.queryHash.current = '';
    update_tests();
  }

  name_comment() {
    return false;
  }
}

export function update_tests(params = {}) {
  let query = {};
  if (globalThis.report_search_doc) {
    query = globalThis.report_search_doc.as_doc();
    globalThis.last_query = query;
  }
  if (params['title']) {
    query['title'] = params['title'];
    query['period'] = 'All';
    query['date'] = [first_possible_day / 1000, new Date() / 1000];
  }
  Object.values(query).forEach((value) => {
    if (value && value.error) {
      show_sticky_toast(`${value.error}`, 'Error');
      return false;
    }
  });
  show_tests_table(query);
}

globalThis.material_classes.SearchReport = SearchReport;
