import { timestamp_formatter } from '../common/utils';
import { TabulatorFull as Tabulator } from 'tabulator-tables';
import { create_modal } from '../common/html_elements';
import { rpc } from '../common/crystal_api/rpc';
import { empty_element, show_element } from '../common/ui';
import { _ } from '../common/translations';
import { hide_contents, show_content } from '../common/contents_visibility';
import { ajaxRequestFunc } from '../common/ajax_request_func';
import { init_ligo_navbar } from '../common/navbar';
import { show_sticky_toast } from '../common/toast';

function show_unregistered() {
  hide_contents();
  show_unreg();
  init_ligo_navbar();
  show_element('admin_page_content');
}

function show_cluster() {
  hide_contents();
  active_nodes_list();
  init_ligo_navbar();
  show_element('admin_page_content');
}

function active_nodes_list() {
  document.getElementById('pageAdmin').innerHTML = 'Active Nodes';
  return rpc.node_list().then(get_node_list);
}

function get_node_list(response) {
  const cluster_table_data = [];
  for (let i = 0; i < response.length; i++) {
    if (response[i]['exceptions'].length === 0) {
      response[i]['exceptions'] = 'None';
    }
    cluster_table_data.push({
      _id: response[i]['_id'],
      modified: response[i]['modified'],
      startup: response[i]['startup'],
      beats: response[i]['beats'],
      errors: response[i]['errors'],
      exceptions: response[i]['exceptions'],
      concurrency: response[i]['concurrency'],
    });
  }
  return show_active_nodes(cluster_table_data);
}

function show_active_nodes(data) {
  const table = new Tabulator('#admin_table', {
    pagination: 'local',
    height: 400,
    layout: 'fitDataStretch',
    responsiveLayout: 'collapse',
    columns: [
      {
        title: 'Node Name',
        field: '_id',
        bottomCalc: 'count',
      },
      {
        title: 'Modified(s)',
        field: 'modified',
      },
      {
        title: 'Created',
        field: 'startup',
      },
      {
        title: 'Errors',
        field: 'errors',
      },
      {
        title: 'Beats',
        field: 'beats',
      },
      {
        title: 'Paused',
        field: 'paused',
      },
      {
        title: 'Log',
        field: 'exceptions',
        maxWidth: '500px',
      },
      {
        title: 'Concurrency',
        field: 'concurrency',
      },
    ],
    initialSort: [{ column: '_id', dir: 'desc' }],
    data: data,
  });
  table.on('rowClick', node_command);
}

function node_command_callback(resp) {}

function node_command_execute(data) {
  const sel = document.getElementById('node_command_selbox');
  rpc['node_' + sel.value]({
    rpcnode: data['_id'],
  }).then(node_command_callback);
}

function node_command(e, row) {
  const data = row.getData();
  const template = `
  <p>
    <select id="node_command_selbox" class="rounded-md drop-shadow-md text-black py-o focus:ring-0">
      <option value="restart">Restart</option>
      <option value="reboot">Reboot</option>
      <option value="pause">Pause</option>
      <option value="resume">Resume</option>
      <option value="scan">Scan</option>
    </select>
  </p>
  `;
  create_modal(
    {
      id: 'clusters_command',
      title: 'Send command to cluster',
      content: template,
    },
    [
      {
        button_id: 'node_command_btn',
        button_text: 'Execute',
        func: () => node_command_execute(data),
        delete_modal: true,
      },
    ]
  );
}

function show_unreg() {
  document.getElementById('pageAdmin').innerHTML = 'Unregistered Hashes';
  let table = new Tabulator('#admin_table', {
    ajaxURL: globalThis.rpc_config.url,
    ajaxConfig: {
      method: 'POST',
      mode: 'cors', //set request mode to cors
      //credentials: "omit", //send cookies with the request from the matching origin
      headers: {
        Accept: 'application/json', //tell the server we need JSON back
        'Content-type': 'application/json; charset=utf-8', //set the character encoding of the request
        //"Access-Control-Allow-Origin": "http://localhost:8000",
      },
    },
    progressiveLoad: 'scroll',
    ajaxParams: {
      cmd: 'license_unregistered',
    },
    ajaxRequestFunc: ajaxRequestFunc(unreg_parse_new_data),
    dataSendParams: { size: 'limit' },
    paginationSize: 20,
    height: 400, // set height of table (in CSS or here), this enables the Virtual DOM
    layout: 'fitDataStretch',
    responsiveLayout: 'collapse',
    columns: [
      // Define Table Columns
      {
        title: 'Date',
        field: 'date',
        formatter: timestamp_formatter,
        bottomCalc: 'count',
        sorter: 'date',
        sorterParams: {
          format: 'DD/MM/YYYY hh:mm:ss',
          alignEmptyValues: 'top',
        },
      },
      {
        title: 'Type',
        field: 'cmd_type',
      },
      {
        title: 'Instrument',
        field: 'instrument_name',
      },
      {
        title: 'Nick',
        field: 'localNick',
      },
      {
        title: 'Hash',
        field: 'hash',
      },
    ],
    initialSort: [{ column: 'date', dir: 'desc' }],
  });
  table.on('rowClick', lambda_unreg_hash);
}

function unreg_parse_new_data(response) {
  for (let i = 0; i < response.length; i++) {
    let elem = response[i];
    elem.date = new Date(elem.doc_time * 1000);
    if (elem.serial) {
      elem.instrument_name = elem.serial;
    } else {
      elem.instrument_name = elem.recent_server;
    }
    elem.cmd_type = {
      renew_server_license: 'Instrument',
      renew_client_license: 'Client',
    }[elem.cmd];
  }
  return [response, {}];
}

function lambda_unreg_hash(e, row) {
  let target;
  row = row.getData();
  if (row.cmd === 'renew_client_license') {
    target = 'client';
  } else if (row.cmd === 'renew_server_license') {
    target = 'instrument';
  } else {
    console.log('NO TARGET');
    return;
  }

  rpc
    .license_list_registars({
      target: target,
    })
    .then((response) => choose_unreg_hash(row, response));
}

function choose_unreg_hash(row, response) {
  const hash = row['hash'];
  const customer_info = {};
  for (let r of response) {
    customer_info[r['_id']] = `${r['customer_id'].toString()}: ${r['localNick'] || r['serial']}(${r['_id']})`;
  }
  const template = `
    <p>
    Hash: <b>${hash}</b>
    <br/>
    <select id="hash_to_instrument_selector" class="rounded-md drop-shadow-md text-black py-0 focus:ring-0"></select>
    </p>
  `;
  create_modal(
    {
      id: 'unregistered_hash',
      title: `Add hash to customer`,
      content: template,
    },
    [
      {
        button_id: 'add_hash_button',
        button_text: 'Add',
        func: () => call_add_hash_to_customer(hash),
        delete_modal: true,
      },
    ]
  );
  const select = document.getElementById('hash_to_instrument_selector');
  for (const [k, v] of Object.entries(customer_info)) {
    const op = `<option value="${k}">${v}</option>`;
    select.insertAdjacentHTML('beforeend', op);
  }
}

function call_add_hash_to_customer(hash) {
  const value = document.getElementById('hash_to_instrument_selector').value;

  rpc
    .license_register({
      hash: hash,
      registar_id: value,
    })
    .then(add_hash_to_customer);
}

function add_hash_to_customer() {
  show_sticky_toast('Hash added to customer.');
  show_unreg();
}

function render_balances() {
  const ppm_balances = `
  <div>
    <h1 class="font-bold">All customers balances</h1>
  </div>
  `;
  document.getElementById('admin_page_content').insertAdjacentHTML('beforeend', ppm_balances);
  return true;
}

function balances_table(el, d) {
  var data = [];
  for (const [s, a] of Object.entries(d)) {
    if (['amount', 'cid'].includes(s) || s.startsWith('incr_')) {
      continue;
    }
    data.push({
      serial: s,
      cumulative: a['cumulative'],
      overall: a['overall'],
      time: a['time_start'],
      c_start: a['cumulative_start'],
    });
  }
  const table = new Tabulator(el, {
    pagination: 'local',
    height: 200,
    layout: 'fitDataStretch',
    responsiveLayout: 'collapse',
    columns: [
      {
        title: 'Instrument serial',
        field: 'serial',
        bottomCalc: 'count',
      },
      {
        title: 'Starting date',
        field: 'time',
        formatter: function (cell) {
          if (cell.getValue()) {
            return new Date(cell.getValue() * 1000).toLocaleDateString('it-IT');
          } else {
            return '//';
          }
        },
      },
      {
        title: 'Last year expenses (€)',
        field: 'cumulative',
        formatter: function (cell) {
          if (cell.getValue()) {
            return cell.getValue().toFixed(2);
          } else {
            return '//';
          }
        },
      },
      {
        title: 'Total expenses (€)',
        field: 'overall',
        formatter: function (cell) {
          if (cell.getValue()) {
            return cell.getValue().toFixed(2);
          } else {
            return '//';
          }
        },
      },
      {
        title: 'Cumulative at the starting date (€)',
        field: 'c_start',
        formatter: function (cell) {
          if (cell.getValue()) {
            return cell.getValue().toFixed(2);
          } else {
            return '//';
          }
        },
      },
    ],
    initialSort: [{ column: 'time', dir: 'asc' }],
    data: data,
  });
  return true;
}

function customers_balances(resp) {
  if (resp.error) {
    return false;
  }

  const cont = document.getElementById('admin_page_content');
  for (const [customer_name, customer_data] of Object.entries(resp)) {
    const customer = `
    <h3 class="inline-block text-elsred pt-4">${customer_name}</h3>
    <span>(customer ${customer_data['cid']})</span>
    <p>Total amount: ${customer_data['amount'].toFixed(2)}</p>
    <div id="customer_${customer_data['cid']}_table"></div>
    <h5>Customer credit increments</h5>
    `;
    //Intestazione dell'azienda
    cont.insertAdjacentHTML('beforeend', customer);
    //Tabella
    balances_table(document.getElementById(`customer_${customer_data['cid']}_table`), customer_data);
    //Elenco ricariche
    const credits = `
    <p id="customer_${customer_data['cid']}_increments">
      <span id="customer_${customer_data['cid']}_no_increments">No credits increments<span>
      <br>
    </p>
    `;
    cont.insertAdjacentHTML('beforeend', credits);
    for (const [data, value] of Object.entries(customer_data)) {
      if (data.startsWith('incr_')) {
        document.getElementById(`customer_${customer_data['cid']}_no_increments`).classList.add('hidden');
        const date = new Date(data.split('_')[1] * 1000).toLocaleDateString('it-IT');
        const increment = `
        <span>${date}: ${value}</span>
        `;
        document
          .getElementById(`customer_${customer_data['cid']}_increments`)
          .insertAdjacentHTML('beforeend', increment);
      }
    }
  }
}

function show_balance() {
  empty_element('admin_page_content');
  render_balances();
  rpc.balance_get_all_customers().then(customers_balances);
  show_content(document.getElementById('admin_page_content'));
  init_ligo_navbar();

  return true;
}

export { show_unregistered, show_cluster, show_balance };
