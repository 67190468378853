import { rpc } from '../common/crystal_api/rpc';
import { ReportConf } from './report_conf';
import { empty_element, show_element_with_opacity_transition } from '../common/ui';
import { build_conf } from '../glaze/show_dict_properties';

function show_report(uid) {
  load_report_data(uid);
  return set_query_param('uid', uid);
}

globalThis.show_report_updating_history = (uid) => {
  load_report_data(uid);
  return set_query_param_updating_history('uid', uid);
};

export function load_report_data(uid) {
  rpc.doc({ uid: uid }).then(get_report_style);
}

function get_report_style(doc) {
  if (doc['error']) {
    document.getElementById('report_name').innerHTML = 'FAILED! ' + doc['error'];
    return false;
  }
  const style_id = doc['style'][0];
  rpc.doc({ uid: style_id }).then((response) => render_report(doc, response));
}

function render_report(doc, style) {
  console.log('Render Report');
  const is_not_report = !doc['class_name'].startsWith('Report');
  const ligo_document = is_not_report ? build_conf(doc, style) : new ReportConf(doc, style);
  delete globalThis.current_document_conf;
  globalThis.current_document_conf = ligo_document;
  empty_element('document_container');
  ligo_document.view();
  show_element_with_opacity_transition('document_container');
}

export { show_report };
