import { populate_tests_table } from './tests';
import { rpc } from '../common/crystal_api/rpc';
import { create_report_home_page, remove_element } from '../common/ui';
import { get_vars } from '../common/url';
import { update_tests } from './search_report';
import { get_values_from, sleep } from '../common/utils';
import { SearchReport } from './search_report';
import { Tabulator } from 'tabulator-tables';

async function on_load_tests() {
  const date_uid = globalThis.report_search_doc['options']['date']['uid'];
  let date_min_ready;
  let date_max_ready;
  await new Promise(async (resolve) => {
    while (!date_min_ready && !date_max_ready) {
      date_min_ready = document.getElementById(`${date_uid}_input_min`)?.getAttribute('ready');
      date_max_ready = document.getElementById(`${date_uid}_input_max`)?.getAttribute('ready');
      await sleep(5);
    }
    return resolve();
  });
  remove_element('expenses_div');
  globalThis.tests_table_list = [];
  update_tests();
}

globalThis.report_search_doc = undefined;

export async function ligo_search_report_using_queryhash(styles, doc = {}) {
  const queryHash = get_vars()['queryHash'];
  if (queryHash) {
    doc = await rpc
      .doc({ uid: `0R:SearchReport#${queryHash}` }, undefined, undefined, undefined, false)
      .catch(() => set_query_param_updating_history('queryHash'));
  }

  return ligo_search_report(styles, doc);
}

export function ligo_search_report(styles, doc = {}) {
  create_report_home_page();
  const values = get_values_from(styles, doc);
  values['_id'] = styles['_id'];
  values['mtype'] = 'SearchReport';
  let url_vars = get_vars();
  delete url_vars['queryHash'];
  delete url_vars['search'];
  const dict = Object.assign({}, values, url_vars);
  globalThis.report_search_doc = new SearchReport(dict, styles);
  globalThis.report_search_doc.view(document.getElementById('search_container'));
}

function tests_table_download() {
  const current_table = Tabulator.findTable('#search_content_table')[0];
  current_table.download('xlsx', 'search_table.xlsx', {
    sheetName: 'Search table',
  });
}

function tests_table_load_all() {
  globalThis.tests_table_list = [];
  populate_tests_table(true, globalThis.last_query);
}

function initialize_range_input(element) {
  element.setAttribute('ready', true);
}

function toggle_color(elem, option_type = 'RangeFloat') {
  if (option_type !== 'RangeFloat') {
    return false;
  }
  const value = elem.value;
  const abs_value = Math.abs(value);
  const min = Math.abs(elem.min);
  const max = Math.abs(elem.max);
  if (['-1', -1].includes(value) || !Number.isFinite(parseFloat(value))) {
    elem.classList.add('text-gray-300');
  } else if ((value < 0 && abs_value > min) || (elem.max && abs_value > max)) {
    elem.classList.add('text-gray-300');
  } else {
    elem.classList.remove('text-gray-300');
  }
}

globalThis.on_load_tests = on_load_tests;
globalThis.initialize_range_input = initialize_range_input;
globalThis.toggle_color = toggle_color;
globalThis.tests_table_download = tests_table_download;
globalThis.tests_table_load_all = tests_table_load_all;
