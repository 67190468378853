import { rpc } from '../common/crystal_api/rpc';
import { empty_element, show_element } from '../common/ui';
import { show_ephemeral_toast } from '../common/toast';
import { _ } from '../common/translations';

function import_init() {
  set_import_page();
}

function update_load_files(number, size) {
  document.getElementById('files_number').innerHTML = number;
  document.getElementById('files_size').innerHTML = size;
}

function format_bytes(bytes, decimals = 2) {
  if (bytes === 0) {
    return '0 Bytes';
  }
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

globalThis.delete_drop_files = function delete_drop_files(name, size) {
  for (const k of Object.keys(upload_files)) {
    if (name.split('.')[0] === k) {
      document.getElementById('file_' + name).remove();
      delete upload_files[k];
      n_files -= 1;
      s_files = Math.round(s_files - size);
      update_load_files(n_files, format_bytes(s_files));
    }
  }
};

function upload_cb(file, resp) {
  console.log('FILE UPLOAD', file, resp, globalThis.get_cookie('logsessid'));
  show_ephemeral_toast(_('File uploaded!'), 'Info');
}

function add_file_to_zone(loading_file) {
  var name = loading_file.name.split('.')[0];
  if (!upload_files[name]) {
    upload_files[name] = loading_file;
  } else {
    show_ephemeral_toast(`${_('files-duplicate')}: <b>${loading_file.name}</b>`, 'Error');
    return;
  }
  var p = document.getElementById('import_drop_zone');
  const load_file = `
  <div id="file_${loading_file['name']}">
    <p class="upload-file-span">
      <span x-text='_("File name:")'></span> 
        ${loading_file['name']}, 
      <span x-text='_("size:")'></span>
        ${format_bytes(loading_file['size'])}
      <button id="delete_file_${loading_file['name']}"
        :class="get_outline_button_classes()"
        @click="delete_drop_files('${loading_file.name}', ${loading_file.size})"
        x-text='_("Remove")'
      ></button>
      </p>
  </div>
  `;
  p.insertAdjacentHTML('beforeend', load_file);
  n_files += 1;
  s_files = Math.round(s_files + loading_file['size']);
  update_load_files(n_files, format_bytes(s_files));
}

function drop_handler(event) {
  let file;
  event.preventDefault();
  document.getElementById('import_drop_zone').classList.remove('bg-slate-100');
  if (event.dataTransfer.items) {
    for (let i = 0; i < event.dataTransfer.items.length; i++) {
      if (event.dataTransfer.items[i].kind === 'file') {
        file = event.dataTransfer.items[i].getAsFile();
        add_file_to_zone(file);
      }
    }
  } else {
    for (let i = 0; i < event.dataTransfer.files.length; i++) {
      file = event.dataTransfer.files[i];
      add_file_to_zone(file);
    }
  }
}

let upload_files = {};
let n_files = 0;
let s_files = 0;

function upload_files_from_zone() {
  const date = new Date();
  const id = date.getDate() + '-' + date.getMonth() + '-' + date.getFullYear();
  for (const [filename, file_content] of Object.entries(upload_files)) {
    console.log('FILE UPLOAD', globalThis.get_cookie('logsessid'));
    const dirname = filename.split('.')[0];
    const doc_info = {
      db: '__import',
      uid: id,
      option: dirname,
      aid: filename,
    };
    rpc.upload(doc_info, file_content).then((response) => upload_cb(file_content, response));
  }
  empty_element('import_drop_zone');
  upload_files = {};
  update_load_files(0, 0);
}

function set_import_page() {
  empty_element('content');
  show_element('content');
  const import_template = require('../view/import_page.handlebars');
  const import_html = import_template({});
  document.getElementById('content').insertAdjacentHTML('beforeend', import_html);
}

globalThis.drop_handler = drop_handler;
globalThis.upload_files_from_zone = upload_files_from_zone;

export { import_init };
