let all_expenses = [];
let all_dates = [];

function reset() {
  all_expenses = [];
  all_dates = [];
}

function update(new_data, chart) {
  const tests_expenses = new_data.map((data) => data.cost * 100);
  const tests_dates = new_data.map((data) => data.created);

  all_expenses = all_expenses.concat(tests_expenses);
  all_dates = all_dates.concat(tests_dates);

  const all_expenses_sorted = all_expenses.slice().reverse();
  const all_dates_sorted = all_dates.slice().reverse();

  const cumulative = all_dates_sorted.map((current_date, index) => {
    const last_cumulative_expense = index > 0 ? all_expenses_sorted[index - 1] : 0;

    all_expenses_sorted[index] ||= 0;
    all_expenses_sorted[index] += last_cumulative_expense;

    return [all_dates_sorted[index], all_expenses_sorted[index]];
  });

  const rounded_cumulative = cumulative.map((c) => [c[0], parseInt(c[1]) / 100]);
  chart.setOption({ series: [{ data: rounded_cumulative }] });
  return rounded_cumulative.slice().pop()?.slice().pop() || 0;
}

export { reset, update };
