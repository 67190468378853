import { struct } from './struct';

function binary_cast(data, input_fmt, output_fmt) {
  const in_struct = struct(input_fmt);
  data = in_struct.pack.apply(this, data);
  const out_struct = struct(output_fmt);
  return out_struct.unpack(data);
}

function decouple(v, scale = 9) {
  const out = [];
  for (let e of v) {
    out.push(e % scale);
    out.push(Math.floor(e / scale));
  }
  if (v.slice(-1)[0] === 4) {
    return out.slice(0, -1);
  }
  return out;
}

function decumulate_coords(x0, y0, flattened) {
  const decoupled = decouple(flattened);
  const x = [x0];
  const y = [y0];
  for (let e of decoupled) {
    e = e - 4;
    const a = Math.abs(e);
    const sgn = Math.sign(e);
    x.push(x.slice(-1)[0] + (a > 1) * sgn);
    y.push(y.slice(-1)[0] + (a !== 3) * sgn * (1 - 2 * (a === 2)));
  }
  return [x, y];
}

function cumulativeProfile_decode(flattened) {
  const res = binary_cast(flattened.slice(0, 16), '<16B', 'dHHHH');
  const t = res[0];
  const w = res[1];
  const h = res[2];
  const x0 = 0;
  let y0 = 800;
  let ret = decumulate_coords(x0, y0, flattened.slice(16));
  const x = ret[0];
  const y = ret[1];
  ret = [t, [[w, h], x, y]];
  globalThis.ret = [x0, y0, ret];
  return ret;
}

export { cumulativeProfile_decode };
