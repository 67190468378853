import { get_document_hash, to_float } from '../common/utils';
import * as echarts from 'echarts';
import { reset as reset_chart, update as update_chart } from './chart_data';
import { ajaxRequestFunc } from '../common/ajax_request_func';
import { dummy_element } from '../common/html_elements';
import { hide_element_with_opacity_transition } from '../common/ui';
import { table_columns_definition } from '../common/tabulator/autocolumns_definitions';
import { create_tabulator } from '../common/tabulator/tabulator_factory';
import { add_doc_to_fast_compare } from '../glaze/fast_compare';

let myChart = undefined;

function report_from_tests_row(e, row) {
  const report_uid = globalThis.tests_table_list[row.getPosition() - 1]['_id'];
  let report_path = get_document_hash(report_uid);
  if (e.ctrlKey) {
    globalThis.open(report_path);
  } else {
    hide_element_with_opacity_transition('document_container');
    show_report_updating_history(report_uid);
  }
}

let tests_serials = [];

function hide_costs(costs_table, expenses_div) {
  expenses_div.classList.add('hidden');
  costs_table.hideColumn('discount');
  costs_table.hideColumn('cost');
  costs_table.hideColumn('cumulative');
}

function show_costs(costs_table, expenses_div) {
  if (expenses_div) expenses_div.classList.remove('hidden');
  costs_table.showColumn('discount');
  costs_table.showColumn('cost');
  costs_table.showColumn('cumulative');
}

export function tests_parse_new_data(response) {
  console.log('report table parse new data');
  const data = response.list;
  try {
    globalThis.tests_table_list = globalThis.tests_table_list.concat(data);
    data.forEach((elem) => {
      elem.created = new Date(elem.created * 1000);
      if (tests_serials.indexOf(elem.serial) < 0) {
        tests_serials.push(elem.serial);
      }
      elem.class_name = get_icon_class_name(elem['_id'], elem['class_name']);
    });
    const current_cumulative = update_chart(data, myChart);
    const current_cumulative_element = document.getElementById('current_cumulative');
    if (current_cumulative_element) current_cumulative_element.innerHTML = to_float(current_cumulative);
    const expenses_div = document.getElementById('expenses_div') || dummy_element();
    if (current_cumulative <= 0) {
      hide_costs(globalThis.tests_report_table, expenses_div);
    } else {
      show_costs(globalThis.tests_report_table, expenses_div);
    }
    return [data, response];
  } catch {
    console.warn('IMPROPER RESPONSE FROM BACKEND: ', response);
    return [response, response];
  }
}

function show_tests_chart() {
  const expenses_div = document.getElementById('expenses_div');
  if (expenses_div) {
    expenses_div.remove();
  }
  reset_chart();

  const chart_div = `
<div id="expenses_div" class="hidden pt-14">
    <h2 x-text='_("Cumulative Expenses")'></h2>
    <div>Total: <span id="current_cumulative">0.00</span></div>
    <div id="expenses" class="h-96 w-full lg:w-[700px] overflow-auto resize"></div>
</div>`;

  document.getElementById('search_div').insertAdjacentHTML('beforeend', chart_div);
  const element = document.getElementById('expenses');

  myChart = echarts.init(element, 'light_plot');
  element.onclick = function () {
    myChart.resize();
  };
  const option = {
    toolbox: {
      itemSize: 200,
      dataZoom: { show: true },
      dataView: { show: true },
    },
    legend: { show: true },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
      },
      valueFormatter: (value) => to_float(value),
    },
    xAxis: { type: 'time' },
    yAxis: { name: 'cost' },
    series: { type: 'line', name: 'expenses', data: [] },
  };
  myChart.setOption(option);
}

function show_tests_table(request, destination = false) {
  globalThis.tests_table_list = [];
  tests_serials = [];
  populate_tests_table(false, request, destination);
}

async function previous_tabulator_fully_loaded() {
  if (globalThis.tests_report_table) {
    await new Promise((resolve) => {
      if (globalThis.data_already_loaded) {
        return resolve();
      }
      globalThis.tests_report_table.on('dataLoaded', function (data) {
        return resolve();
      });
    });
  }
}

async function populate_tests_table(load_all = false, last_query = {}, destination = false) {
  if (!document.getElementById('search_content_table')) {
    return;
  }
  await previous_tabulator_fully_loaded();
  const ajaxParams = () => {
    return { cmd: 'list' };
  };
  let table_tooltip = 'ctrl-to-open';
  let row_click = destination
    ? (e, row) => add_report_to_role(row, destination)
    : (e, row) => report_from_tests_row(e, row);
  if (destination === 'compare' && globalThis.comparing) {
    table_tooltip = _('Click to add to comparison');
    row_click = (e, row) => add_doc_to_fast_compare(row['_row']['data']);
  }
  const table = create_tabulator(
    '#search_content_table',
    ajaxParams,
    ajaxRequestFunc(tests_parse_new_data, last_query),
    table_columns_definition(_(table_tooltip)),
    load_all,
    row_click
  );

  globalThis.tests_report_table = table;
  globalThis.tests_report_table.on('renderComplete', () => {
    globalThis.data_already_loaded = true;
  });
  show_tests_chart();
}

function add_report_to_role(row, destination) {
  const doc = row['_row']['data'];
  destination.receive_new_current_from_table(doc);
}

export { show_tests_table, populate_tests_table };
