import { rpc } from '../common/crystal_api/rpc';
import { show_ephemeral_toast } from '../common/toast';
import { create_modal } from '../common/html_elements';
import { _ } from '../common/translations';
import { get_load_callback, get_search_type, search_document_options } from '../common/search_document_utils';
import { ligo_search_report_using_queryhash } from './search_report_functions';

function reset_table() {
  rpc
    .doc({
      uid: 'def:Style#SearchReport#0',
    })
    .then((response) => {
      ligo_search_report_using_queryhash(response, { force_initialization: true });
    });
}

function save_search_document() {
  const dialog_template = require('../view/save_search_document.handlebars');
  const dialog_html = dialog_template({
    name_input: 'search_document_name',
    comment_input: 'search_document_comment',
  });
  create_modal(
    {
      id: 'save_search_document_dialog',
      title: _('Save search filters in a document'),
      content: dialog_html,
    },
    [
      {
        button_text: 'Confirm',
        button_id: 'save_search_document_btn',
        func: save_search_document_confirm,
        delete_modal: false,
      },
    ]
  );
  document.getElementById('search_document_name').focus();
  return;
}

function load_search_document() {
  const search_type = get_search_type();
  rpc
    .glaze_list({
      classes: [search_type],
    })
    .then((response) => {
      if (response.list.length) {
        get_search_docs(response.list);
      } else {
        show_ephemeral_toast(_('No document found'), 'Error');
        return;
      }
    });
}

function save_search_document_confirm() {
  const doc_name = document.getElementById('search_document_name').value;
  if (!doc_name || !new RegExp('^[a-zA-Z0-9 ]*$').test(doc_name)) {
    show_ephemeral_toast(_('invalid-name'), 'Info');
    return;
  }
  const options = search_document_options();
  const dict = {};
  for (const [key, value] of Object.entries(options)) {
    dict[key] = value.current_from_user();
  }
  dict['name'] = document.getElementById('search_document_name').value;
  dict['comment'] = document.getElementById('search_document_comment').value;
  document.getElementById('save_search_document_dialog').remove();
  rpc.create({ doc: dict }).then(show_ephemeral_toast(_('search-document-saved'), 'Info'));
}

async function search_doc_selected() {
  let doc = {};
  const select = document.getElementById('load_search_document');
  const selected = select.options[select.selectedIndex];
  await rpc.doc({ uid: selected.value }).then((document) => {
    doc = document;
  });
  const search_type = get_search_type();
  const load_callback = get_load_callback();
  rpc
    .doc({
      uid: `def:Style#${search_type}#0`,
    })
    .then((response) => load_callback(response, doc));
}

function get_search_docs(doc_list) {
  const template = require('../view/load_search_document.handlebars');
  const options = [];
  for (let doc of doc_list) {
    options.push({ value: doc['_id'], text: doc['name'] });
  }
  const desc = { select_id: 'load_search_document' };
  desc['options'] = JSON.stringify(options);
  const document_html = template(desc);
  create_modal(
    {
      id: 'load_search_document_dialog',
      title: _('Load saved search document'),
      content: document_html,
    },
    [
      {
        button_text: 'Load',
        button_id: 'load_search_document_btn',
        func: search_doc_selected,
        delete_modal: true,
      },
    ]
  );
}

globalThis.reset_table = reset_table;
globalThis.save_search_document = save_search_document;
globalThis.load_search_document = load_search_document;
