const colTEMP = 1;

function power_event_duration(ev) {
  if (typeof ev == 'string') {
    ev = ev.split(',');
  }
  const power1 = parseFloat(ev[1]);
  const power2 = parseInt(ev[2]);
  const power3 = parseFloat(ev[3]);
  return power1 * power2 + power3;
}

const move_open_close_wait = 60;

function move_event_duration(ev) {
  if (typeof ev == 'string') {
    ev = ev.split(',');
  }
  if (ev.length === 2) {
    ev.append(0);
  }
  const move_ev_duration = parseFloat(ev[2]);
  return move_ev_duration + move_open_close_wait;
}

function event_duration(ev) {
  ev = ev.toString();
  if (ev.startsWith('>power') === true) {
    return power_event_duration(ev);
  }
  if (ev.startsWith('>move') === true) {
    return move_event_duration(ev);
  } else {
    return 0;
  }
}

function decode_cool_event(event) {
  if (!event.startsWith('>cool')) {
    return false;
  }
  event = event.split(',');
  const T = parseFloat(event[1]);
  let timeout = null;

  if (event.length > 2) {
    timeout = parseFloat(event[2]);
  }
  return [T, timeout];
}

function next_point(crv, row, delta = 1, events = false) {
  /*    Search next non-event point in thermal cycle curve `crv`.
              Starts by checking `row` index, then adds `delta` (+-1),
              until proper value is found.*/
  const ent = crv.slice(row)[0];
  const n = crv.length;
  while (row >= 0 && row < n) {
    const c = typeof ent[1];
    //Found an event
    if (c === 'string' && !events) {
      row += delta;
      continue;
    }
    break;
  }
  if (row < 0) {
    return -1;
  }
  if (row >= n) {
    return n - 1;
  }
  return [row, ent];
}

let t = 0;
let T = 0;

function setCurve(crv) {
  const dat = [];

  for (let i = 0; i < crv.length; i++) {
    [t, T] = crv[i];
    let D = 0;
    let R = 0;
    if (typeof T == 'string') {
      D = event_duration(T) / 60;
      dat.push([t / 60, T, R, D]);
      continue;
    }
    if (i > 0) {
      let t0;
      let idx = 0;
      let ent = 0;
      const res = next_point(crv, i - 1, -1, true);
      idx = res[0];
      ent = res[1];
      if (typeof ent[1] == 'string') {
        const ev = ent.slice(1).toString();
        if (ev.startsWith('>cool')) {
          const cool_event = decode_cool_event(ent[1]);
          const cT = cool_event[0];
          t0 = cool_event[1];
          ent[1] = cT;
        } else if (ev === '>checkpoint') {
          const check = next_point(crv, i - 1, -1, false);
          idx = check[0];
          ent = check[1];
        } else if (ev === 'move') {
          ent[1] = crv[0][colTEMP];
        } else {
          ent = false;
        }
      }
      if (ent === false) {
        ent = [t, T];
      }
      const T0 = ent[1];
      t0 = ent[0];
      D = (t - t0) / 60;
      if (T === T0 || D === 0) {
        R = 0;
      } else {
        R = (T - T0) / D;
      }
    }
    dat.push([t / 60, T, R, D]);
  }
  console.log('thermal_cycle curve ready');
  return dat;
}

export { setCurve };
